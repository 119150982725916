import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    isNewPool: true,
	pid: 0,
    risk: 5,
    lpSymbol: 'WATERFALL-WETH LP',
	
    lpAddresses: {
      97: '',
      42161: '0x29519bcfd1d702363e9Ad63dBd2331C3C7f4A9f7',
    },
    tokenSymbol: 'WATERFALL',
    tokenAddresses: {
      97: '',
      42161: '0xedBF59b40336244c6ea94A11a6B0cF6864c87E83',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
    tokenDecimals: 18,
  },
  {
    isNewPool: true,
	pid: 1,
    risk: 5,
    lpSymbol: 'WATERFALL-USDC LP',
	
    lpAddresses: {
      97: '',
      42161: '0x18B60b6b6a14D7C33D5086fA84871d519136C064',
    },
    tokenSymbol: 'WATERFALL',
    tokenAddresses: {
      97: '',
      42161: '0xedBF59b40336244c6ea94A11a6B0cF6864c87E83',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: true,
	pid: 18,
    risk: 5,
    lpSymbol: 'WATERFALL-FISH LP',
	
    lpAddresses: {
      97: '',
      42161: '0xE0326370B3cfB11B07db0619A34DB0333b530B66',
    },
    tokenSymbol: 'FISH',
    tokenAddresses: {
      97: '',
      42161: '0xb348B87b23D5977E2948E6f36ca07E1EC94d7328',
    },
    quoteTokenSymbol: QuoteToken.FISH,
    quoteTokenAdresses: contracts.fish,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 19,
    risk: 5,
    lpSymbol: 'WBTC-USDC LP',
	
    lpAddresses: {
      97: '',
      42161: '0x9A027A8B8Db40A97a4d33633884721d60c132b5d',
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      97: '',
      42161: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 14,
    risk: 5,
    lpSymbol: 'FISH-WETH LP',
	
    lpAddresses: {
      97: '',
      42161: '0xE0a0ede95abe2072Cc2091d5C193FD86F740E057',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '',
      42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.fish,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 15,
    risk: 5,
    lpSymbol: 'FISH-USDC LP',
	
    lpAddresses: {
      97: '',
      42161: '0x570Eab8422160840ce3b7b2BE4c71D657659868f',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      42161: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 2,
    risk: 5,
    lpSymbol: 'FRAX-USDC LP',
	
    lpAddresses: {
      97: '',
      42161: '0x85710698eB1fB588aF3996a268e73938795Bf558',
    },
    tokenSymbol: 'FRAX',
    tokenAddresses: {
      97: '',
      42161: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 3,
    risk: 5,
    lpSymbol: 'WETH-USDC LP',
	
    lpAddresses: {
      97: '',
      42161: '0x8Bc2Cd9DAB840231A0Dab5B747b8A6085c4eA459',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '',
      42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 4,
    risk: 5,
    lpSymbol: 'DAI-USDC LP',
	
    lpAddresses: {
      97: '',
      42161: '0x978DE910e7645fA7861aF89a156D3c86ad09Db79',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      42161: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 6,
    risk: 5,
    lpSymbol: 'USDT-USDC LP',
	
    lpAddresses: {
      97: '',
      42161: '0x106AE154e4c24b6e11E70cfee7E075B14a182244',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      42161: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  // {
  //   pid: 2,
  //   risk: 5,
  //   lpSymbol: 'WETH-USDC',
  //   lpAddresses: {
  //     250: '0x905dfCD5649217c42684f23958568e533C711Aa3',
  //   },
  //   tokenSymbol: 'WETH',
  //   tokenAddresses: {
  //     250: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  {
    isNewPool: true,
	pid: 5,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WATERFALL',
	
    lpAddresses: {
      97: '',
      42161: '0x18B60b6b6a14D7C33D5086fA84871d519136C064', // WATERFALL-BUSD LP
    },
    tokenSymbol: 'WATERFALL',
    tokenAddresses: {
      97: '',
      42161: '0xedBF59b40336244c6ea94A11a6B0cF6864c87E83',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 12,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'FISH',
	
    lpAddresses: {
      42161: '0x570Eab8422160840ce3b7b2BE4c71D657659868f',
    },
    tokenSymbol: 'FISH',
    tokenAddresses: {
      42161: '0xb348B87b23D5977E2948E6f36ca07E1EC94d7328',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: true,
	pid: 20,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'AGEUR',
	
    lpAddresses: {
      42161: '0x78d9B037Fb873AfCf4e3E466aDfAfa8A5258CdaD',
    },
    tokenSymbol: 'AGEUR',
    tokenAddresses: {
      42161: '0xFA5Ed56A203466CbBC2430a43c66b9D8723528E7',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 17,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'GMX',
	
    lpAddresses: {
      42161: '0xB2A72A9FA965adE9bD21b1a60d29ccCE5E2C5A62',
    },
    tokenSymbol: 'GMX',
    tokenAddresses: {
      42161: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 16,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'VST',
	
    lpAddresses: {
      42161: '0x6832EF9928a7AD3F887e34D4F9f70A3CEed0C1b4',
    },
    tokenSymbol: 'VST',
    tokenAddresses: {
      42161: '0x64343594Ab9b56e99087BfA6F2335Db24c2d1F17',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 6,
  },
  {
    isNewPool: false,
	pid: 7,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WETH',
	
    lpAddresses: {
      42161: '0x8Bc2Cd9DAB840231A0Dab5B747b8A6085c4eA459',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 8,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'USDC',
	
    lpAddresses: {
      42161: '0x106AE154e4c24b6e11E70cfee7E075B14a182244',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      42161: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 6,
  },
  {
    isNewPool: false,
	pid: 9,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'MIM',
	
    lpAddresses: {
      42161: '0x23d7CB985B5b23d83E5c2eb04074649aF1d488bf',
    },
    tokenSymbol: 'MIM',
    tokenAddresses: {
      42161: '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 10,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WBTC',
	
    lpAddresses: {
      42161: '0x9A027A8B8Db40A97a4d33633884721d60c132b5d',
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      42161: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 11,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'USDT',
	
    lpAddresses: {
      42161: '0x106AE154e4c24b6e11E70cfee7E075B14a182244',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      42161: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
  {
    isNewPool: false,
	pid: 13,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'FRAX',
	
    lpAddresses: {
      42161: '0x85710698eB1fB588aF3996a268e73938795Bf558',
    },
    tokenSymbol: 'FRAX',
    tokenAddresses: {
      42161: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    tokenDecimals: 18,
  },
]

export default farms
