import { MenuEntry } from '@pancakeswap-libs/uikit'


const config: MenuEntry[] = [
  {
    label: 'WTFX PROJECT',
    icon: 'HomeIcon',
    href: 'https://exchange.wtfdex.com/',
  },
  {
    label: 'Dashboard',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://swapfish.fi/swap?outputCurrency=0xedBF59b40336244c6ea94A11a6B0cF6864c87E83',
      },
      {
        label: 'Liquidity',
        href: 'https://swapfish.fi/add/0xedBF59b40336244c6ea94A11a6B0cF6864c87E83/ETH',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: 'https://lottery.defiwaterfall.com/',
  },
  {
    label: 'Bridge',
    icon: 'NftIcon',
    href: '/bridge',
  },
  {
    label: 'Fiat Gateway',
    icon: 'LotteryIcon7',
    href: '/fiat',
  },
  {
    label: 'Dividends',
    icon: 'GooseIcon7',
    href: '/dividends',
  },
  {
    label: 'YieldWolf Vaults',
    icon: 'AuditIcon',
    href: 'https://yieldwolf.finance/arbitrum/0x33141e87ad2DFae5FBd12Ed6e61Fa2374aAeD029/v2-21',
  },
  {
    label: 'Matrix Vaults',
    icon: 'AuditIcon2',
    href: 'https://matrix.farm/',
  },
  {
    label: 'Graph',
    icon: 'IfoIcon',
    href: 'https://dexscreener.com/arbitrum/0x18B60b6b6a14D7C33D5086fA84871d519136C064',
  },
  {
    label: 'Listings',
    icon: 'InfoIcon',
    items: [
      {
        label: 'CoinGecko',
        href: '',
      },
      {
        label: 'CoinMarketCap',
        href: '',
      },
	  {
        label: 'Defillama',
        href: 'https://defillama.com/protocol/waterfall-bsc',
      },
	  {
        label: 'VFAT',
        href: 'https://vfat.tools/arbitrum/waterfall/',
      },
	  {
        label: 'Nomics',
        href: 'https://nomics.com/assets/waterfallarb-waterfall-finance-arbitrum',
      },
	  {
        label: 'DappRadar',
        href: 'https://dappradar.com/arbitrum/high-risk/waterfall-finance',
      },
	  {
        label: 'GeckoTerminal',
        href: 'https://www.geckoterminal.com/arbitrum/pools/0x29519bcfd1d702363e9ad63dbd2331c3c7f4a9f7',
      },
    ],
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Whitepaper',
    icon: 'AuditIcon1',
    href: 'https://documentation.defiwaterfall.com/',
  },
]

export default config
