export default {
  cake: {
    42161: '0xedBF59b40336244c6ea94A11a6B0cF6864c87E83',
  },
  masterChef: {
    42161: '0xe9960f14B5f0713D1d530C1fF079A7adAb7c076D',
  },
  sousMasterChef: {
    42161: '0xD3f543b3D2C095198D034cBFbEca5e077551F5E9',
  },
  weth: {
    42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
  },
  lottery: {
    42161: '',
  },
  lotteryNFT: {
    42161: '',
  },
  mulltiCall: {
    42161: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  },
  usdc: {
    42161: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  },
  busd: {
    42161: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  },
  dai: {
    42161: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  },
  usdt: {
    42161: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9', // USDT
  },
  wbtc: {
    42161: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f', // wbtc
  },
  wmatic: {
    42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', // WFTM
  },
  wbnb: {
    42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
  },
  wftm: {
    42161: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'
  },
  matic: {
    42161: '0x0000000000000000000000000000000000001010',
  },
  fish: {
    42161: '0xb348B87b23D5977E2948E6f36ca07E1EC94d7328',
  },
}
