import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
 {
    sousId: 1,
    tokenName: 'DAI',
    stakingTokenName: QuoteToken.WATERFALL,
    stakingTokenAddress: '0xedBF59b40336244c6ea94A11a6B0cF6864c87E83',
    contractAddress: {
      42161: '0xD3f543b3D2C095198D034cBFbEca5e077551F5E9'
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://documentation.defiwaterfall.com/products/dividends',
    harvest: true,
    tokenPerBlock: '0.0000924929',
    sortOrder: 1,
    isFinished: true, 
    tokenDecimals: 18,
  },
]

export default pools
